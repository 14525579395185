import React from "react";

function render(){
	return (
		<div>
			<iframe title="Paint" src="https://jspaint.app/" width="620" height="420"></iframe>
        </div>
	)
}

export default render;