import React from "react";
import Window from "./../Window/Window.js";
import toolbar_top from "./images/ietoolbar.jpg";
import toolbar_bottom from "./images/ietoolbarbottom.jpg";

import UIfx from 'uifx';
import NOTIFY from "./../../assets/sounds/NOTIFY.WAV";
import DING from "./../../assets/sounds/DING.WAV";

const ads = [
'01.gif',
'02.gif',
'03.jpg',
'04.png',
'05.gif',
'hoes.jpg'
];
let dinged = 0;

class InternetExplorer extends React.Component {
  state = {
    broken:false
  }
  componentWillMount(){
    const sfx = new UIfx(
      NOTIFY,
      {
        volume: 1,
        throttleMs: 100
      }
    );
    sfx.play()
  }
  ohNo(){
    this.setState({broken:true});
    setTimeout(this.ding.bind(this),1200);
  }
  ding(){
    dinged++;
    const sfx = new UIfx(
      DING,
      {
        volume: 1,
        throttleMs: 100
      }
    );
    sfx.play()
    if(dinged < ads.length){
      setTimeout(this.ding.bind(this),500);
    }else{
      setTimeout(this.props.bluescreen,500);
    }
  }
  render(){
    const images = require.context("./ads", true);
    let adverts = [];
    for(var i=0;i<ads.length;i++){
      adverts.push( images("./" + ads[i]) );
    }
    return (
      <div onClick={this.ohNo.bind(this)}>
       <img src={toolbar_top} alt="Toolbar" />
       <iframe src="https://poolside.fm" title="Poolside FM" width="420" height="320"></iframe>
       <img src={toolbar_bottom} alt="Toolbar" />
       {this.state.broken && adverts.map((ad, index) => {
          return (
            <div className={"delayShow delay-"+index}>
              <Window
                key={index}
                title={'BUY TODAY'}
                icon="msie1-2"
                randomPos={true}
                onClose={() => false}
              ><img src={ad} alt="BUY THIS SHIT NOW" /></Window>
            </div>
          );
        })}
      </div>
    );
  }
}

export default InternetExplorer;