import React from "react";
import Window from "./../Window/Window.js";

import errorImage from "./images/youwouldnt.jpeg";

import UIfx from 'uifx';
import errorSound from "./../../assets/sounds/CHORD.WAV";

class Limewire extends React.Component {

  state = {
    nope:false
  }
  nope(){
    this.setState({nope:true});

    const sfx = new UIfx(
      errorSound,
      {
        volume: 1,
        throttleMs: 100
      }
    );
    sfx.play()
  }
  noNope(){
    this.setState({nope:false});
  }
  render(){
    return (
      <div>
        {this.state.nope && <Window title='Error' icon="no-1" onClose={this.noNope.bind(this)}>
        <img src={errorImage} alt="You wouldn't"/> </Window>} 
        <div>
            <p className="limewire_menu_item"><span className="underline">F</span>ile</p>
            <p className="limewire_menu_item"><span className="underline">V</span>iew</p>
            <p className="limewire_menu_item"><span className="underline">N</span>avigation</p>
            <p className="limewire_menu_item"><span className="underline">R</span>esources</p>
            <p className="limewire_menu_item"><span className="underline">T</span>ools</p>
            <p className="limewire_menu_item"><span className="underline">H</span>elp</p>
        </div>
            <pre>
              <table className="lime_table">
                  <tr>
                      <td className="lime_table_name">Forgot About Dre ft. Eminem</td>
                      <td>Dr. Dre</td>
                      <td>3:43</td>
                  </tr>
                  <tr>
                      <td className="lime_table_name">Brain Damage</td>
                      <td>Eminem</td>
                      <td>3:46</td>
                  </tr>
                  <tr>
                      <td className="lime_table_name">Full Clip</td>
                      <td>Gang Starr</td>
                      <td>3:43</td>
                  </tr>
                  <tr>
                      <td className="lime_table_name">Mathematics</td>
                      <td>Mos Def</td>
                      <td>4:06</td>
                  </tr>
                  <tr>
                      <td className="lime_table_name">Ruff Ryders' Anthem</td>
                      <td>DMX</td>
                      <td>3:34</td>
                  </tr>
                  <tr>
                      <td className="lime_table_name">Intergalactic</td>
                      <td>Beastie Boys</td>
                      <td>3:51</td>
                  </tr>
                  <tr>
                      <td className="lime_table_name">Still D.R.E.</td>
                      <td>Dr. Dre</td>
                      <td>4:34</td>
                  </tr>
                  <tr>
                      <td className="lime_table_name">Dr. Greenthumb</td>
                      <td>Cypress Hill</td>
                      <td>4:24</td>
                  </tr>
                  <tr onClick={this.nope.bind(this)}>
                      <td className="lime_table_name">Lazy people go hiking podcast</td>
                      <td>Vestless Enterprises</td>
                      <td>0:46</td>
                  </tr>
                  <tr onClick={this.nope.bind(this)}>
                      <td className="lime_table_name">poolside_chat_ep69</td>
                      <td>Poolside FM</td>
                      <td>4:20</td>
                  </tr>
                  <tr onClick={this.nope.bind(this)}>
                      <td className="lime_table_name">best.podcast.everrrr.omg.defnotavirus.exe</td>
                      <td>Won't steal your data</td>
                      <td>0:00</td>
                  </tr>
                  <tr onClick={this.nope.bind(this)}>
                      <td className="lime_table_name">the.joe.brogan.exp.2020.mp5</td>
                      <td>Joe Brogan</td>
                      <td>9:99:99</td>
                  </tr>
              </table>
            </pre>
      </div>
    )
  }
}

export default Limewire;