import React from "react";
import ReactPlayer from "react-player";

const video_list = [
  {
    url:"https://www.youtube.com/watch?v=V7PYQCXdX3A&t=20",
    title:"Bill and Steve's Playdate"
  },
  {
    url:"https://www.youtube.com/watch?v=lAkuJXGldrM",
    title:"Me and the boys releasing an operating system"
  },
  {
    url:"https://www.youtube.com/watch?v=V51OJr0ee6E",
    title:"Computer Man"
  },
  {
    url:"https://www.youtube.com/watch?v=l3eww1dnd0k",
    title:"Computer Man 2.0"
  },
  {
    url:"https://www.youtube.com/watch?v=140ea47ndME",
    title:"Computer Love Camp"
  },
  {
    url:"https://www.youtube.com/watch?v=Pc_yHON1oiQ",
    title:"Blockbuster Training Video [1990]"
  },
  {
    url:"https://www.youtube.com/watch?v=gWOzUzJd6wM",
    title:"80s special FX"
  },
  {
    url:"https://www.youtube.com/watch?v=szdbKz5CyhA",
    title:"Sending an 'E mail'"
  },
  {
    url:"https://www.youtube.com/watch?v=rusqc41h8Hk",
    title:"Yo-yo man just kept on Yo-ing"
  },
];
class VideoPlayer extends React.Component {
  constructor(){
    super();
    this.state = {
      current_video:Math.floor(Math.random()*video_list.length)
    }
  }
  nextVideo(){
    let vidID = this.state.current_video;
    vidID++;
    if(vidID>video_list.length-1) vidID = 0;
    this.setState({current_video:vidID});
  }
  render(){
    return (
      <div className="video">
        <div className="video-title">{video_list[this.state.current_video].title}</div>
        <div className="video-next" onClick={this.nextVideo.bind(this)}>Next</div>
        <div className="video-filter">
          <ReactPlayer
            url={video_list[this.state.current_video].url}
            width={420}
            height={330}
            playing={true}
            muted={true}
            loop={false}
            controls={false}
            playsinline={true}
            onEnded={this.nextVideo.bind(this)}
            style={{
              pointerEvents: "none",
            }}
          />
        </div>
      </div>
    );
  }
}

export default VideoPlayer;