import React from 'react';
import Draggable from 'react-draggable-elements';
//import { Resizable, ResizableBox } from 'react-resizable';

class Window extends React.Component {

	state = {
		active:false,
		attention:true,
		ticker:false,
	}
	constructor(props){
		super(props);
		this.handleClickOutside = this.handleClickOutside.bind(this); //bind function once
  	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside, false);
		document.addEventListener('touchstart', this.handleClickOutside, false);
		if(this.props.needsAttention) setInterval( () => this.setState({ticker:!this.state.ticker} ), 500);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside, false);
		document.removeEventListener('touchend', this.handleClickOutside, false);
	}

	handleClose(){
		this.props.onClose();
	}
	
	handleClickOutside(e) {
		if(! this.node ) return;
		if ( this.node.contains(e.target) ) {
			this.setState({active:true, attention:false});
		}else if(this.state.active){
			this.setState({active:false});
		}
	}

	slugify(str){
		str = str.replace(/^\s+|\s+$/g, ''); // trim
		str = str.toLowerCase();

		str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
			.replace(/\s+/g, '-') // collapse whitespace and replace by -
			.replace(/-+/g, '-'); // collapse dashes

		return str;
	}

	randomCoordinate(){
		return {
			x : Math.random()*(window.innerWidth-200),
			y : Math.random()*(window.innerHeight-200)
		}
	}

	startCoordinate(n){
		// temporary
		const step = 20;
		return {
			x : step * n,
			y : step * n
		}
	}

	isActive(){
		let active = this.state.active;
		if(this.state.attention) return this.state.ticker ? 'active' : 'inactive';
		return active ? 'active current' : 'inactive';
	}

	render(){
		let icon;
	    if (this.props.icon){
		    const images = require.context("../../assets/windows98-icons/png", true);
		    icon = images("./" + this.props.icon + ".png");
		}
		const class_name = this.slugify(this.props.title);
		const activeClass = this.isActive();
		const defaultPosition = !this.props.n ? this.randomCoordinate(): this.startCoordinate(this.props.n);
		return (
			<Draggable handle=".draggable-handle" defaultPosition={defaultPosition}>
				<div className={"window " + class_name + " " + activeClass} ref={node => this.node = node}>
					<div className={"title-bar "+activeClass}>
						{icon && <div className="title-bar-icon"><img src={icon} alt={this.props.title + " Icon"} /></div>}
						<div className="title-bar-text">{this.props.title}</div>
						<div className="title-bar-controls">
							<button aria-label="Close" onClick={this.handleClose.bind(this)}></button>
						</div>
						<div className="draggable-handle"></div>
					</div>
					<div className="window-body">
						{this.props.children}
					</div>
				</div>
			</Draggable>
		);
	}
}
export default Window;