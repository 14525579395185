import React from 'react';

class Maze extends React.Component {

	render(){
		const style = {
			border:'none',
			pointerEvents:'none',
			overflow:'hidden',
		}
		return (
			<iframe title="maze-screensaver" src="/screensaver/maze/" width="100%" height="100%" style={style} />
		);
	}
}
export default Maze;