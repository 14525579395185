import React from 'react';
import icon from "../assets/images/ribbon.gif";
import logo from "../assets/images/energy.gif";

import poolsideLogo from "../assets/images/poolside.svg";

import UIfx from 'uifx';
import StartUpSound from "./../assets/sounds/The Microsoft Sound.wav";

class Intro extends React.Component {

	state = {};

	constructor(){
		super();
		this.state = { page:this.pageOne }
	}

	componentDidMount(){
		setTimeout(this.gotoPageTwo.bind(this),3000);
		document.addEventListener("keydown", ({key}) => {
			if (key === "Delete") window.location.href = "https://www.youtube.com/watch?v=oHg5SJYRHA0";
		})
	}

	gotoPageTwo(){
		this.setState({page:this.pageTwo})
		setTimeout(this.gotoPageThree.bind(this),3000);
	}
	gotoPageThree(){
		this.setState({page:this.pageThree})
	}

	pageOne(){
		return (
			<div className="BIOS">
				<div className="container">
					<img className="EPA" src={logo} alt="Energy EPA Pollution Preventer" />
					<div className="content">
						<img className="ribbon" src={icon} alt="Ribbon Icon" />
						<p>Dank BIOS v69.420
						<br/>Copyright (C) 1998, PoolsideAM</p>
						<div className="typing-hack">
							<p className="text">
								PC Parody LMAO-420<br/>
								Main Processor	: Quad-blazed HI-AF<br/>
								<br/>
								Memory Test	: 420K OK<br/>
								Detecting Primary Big Boi	... 69G Internal Storage (nice)<br/>
								Detecting Primary Smol Boi 	... 69G Memory Card (double nice)<br/>
								<br/>
								<br/>Booting Up Poolside AM
							</p>
							<p className="typing">
								PC Parody LMAO-420<br/>
								Main Processor	: Quad-blazed HI-AF<br/>
								<br/>
								Memory Test	: 420K OK<br/>
								Detecting Primary Big Boi	... 69G Internal Storage (nice)<br/>
								Detecting Primary Smol Boi 	... 69G Memory Card (double nice)<br/>
								<br/>
								<br/>Booting Up Poolside AM
							</p>
						</div>
					</div>
					<div className="instructions">
						<p>Press <strong>DEL</strong> to enter SETUP
						<br/>06/09/1999</p>
					</div>
				</div>
			</div>
		);
	}

	pageTwo(){
		return (
			<div className="BIOS">
				<div className="container">
					<div className="content">

						<div className="typing-hack fast">
							<p className="text">
								CPU Type : It's there<br/>
								Co-Processor : uninstalled<br/>
								CPU Clock : 4:20 eyylmao<br/>
								Base Memory : Completely gone<br/>
								Extended Memory : After all those cocktails probably none<br/>
								Cache Memory : Last 20 seconds<br/>
								---<br/>
								<br/>
								PCI device listing<br/>
								Bus No. 69 (nice)<br/>
								<br/>
								<br/>
								<br/>Preparing Poolside AM
							</p>
							<p className="typing">
								CPU Type : It's there<br/>
								Co-Processor : uninstalled<br/>
								CPU Clock : 4:20 eyylmao<br/>
								Base Memory : Completely gone<br/>
								Extended Memory : After all those cocktails probably none<br/>
								Cache Memory : Last 20 seconds<br/>
								---<br/>
								<br/>
								PCI device listing<br/>
								Bus No. 69 (nice)<br/>
								<br/>
								<br/>
								<br/>Preparing Poolside AM
							</p>
						</div>

					</div>
				</div>
			</div>
		);
	}

	pageThree(){
	    const sfx = new UIfx(
	      StartUpSound,
	      {
	        volume: 1,
	        throttleMs: 100
	      }
	    );
	    sfx.play()
	    return (
		    <div className="startupscreen">
			    <div className="bar"></div>
		    	<img src={poolsideLogo} alt="Logo" />
		    </div>
	    )
	}

	render(){
		return this.state.page();
	}
}
export default Intro;