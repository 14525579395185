import React from "react";

function render(){
  return ( 
    <div>
      <pre contenteditable="true">
          <p>&nbsp;</p>
          <p>---NetScape---</p>
          <p>U: whereskeyser</p>
          <p>P: AFJKFSFLKDF</p>
          <p>&nbsp;</p>
          <p>---AOL---</p>
          <p>U: jcookemn</p>
          <p>P: BoomerDaddy69</p>
          <p>&nbsp;</p>
          <p>---AskJeeves---</p>
          <p>U: lochieaxon</p>
          <p>P: LochieToes1998*?</p>
          <p>&nbsp;</p>
          <p>---Neopets---</p>
          <p>U: brandon_mn</p>
          <p>P: Horny4Minnesota$</p>
          <p>&nbsp;</p>
          <p>---Geocities---</p>
          <p>U: pugson</p>
          <p>P: fuckHappiness_98</p>
      </pre>
    </div>
  )
}

export default render;