import React from "react";
import ReactPlayer from "react-player";

//import dancingbaby from "./../../assets/images/dancingbaby.gif";

import intro01 from "./../../assets/sounds/intros/intro1.mp3";
import intro02 from "./../../assets/sounds/intros/intro2.mp3";
import intro03 from "./../../assets/sounds/intros/intro3.mp3";
import intro04 from "./../../assets/sounds/intros/intro4.mp3";
import intro05 from "./../../assets/sounds/intros/intro5.mp3";
import intro06 from "./../../assets/sounds/intros/intro6.mp3";

import burt from "./images/burt.jpg";

const video_list = [
  {
    url:intro01,
    title:"Welcome to Poolside AM",
    artist:"@itsgregb",
  },
  {
    url:"https://www.youtube.com/watch?v=qImHuiYnVQ0",
    title:"Redesign Your Logo",
    artist:"Lemon Demon",
  },
  {
    url:"https://www.youtube.com/watch?v=eT3BFzSD6YY",
    title:"and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming ",
    artist:"and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming and they don’t stop coming ",
  },
  {
    url:"https://www.youtube.com/watch?v=0tdyU_gW6WE",
    title:"Bustin'",
    artist:"Neil Cicierega",
  },
  {
    url:"https://www.youtube.com/watch?v=l3eww1dnd0k",
    title:"Computer Man",
    artist:"Vid Kids",
  },
  {
    url:intro02,
    title:"Welcome to Poolside AM",
    artist:"@itsgregb",
  },
  {
    url:"https://soundcloud.com/neilcic/mouthdreams",
    title:"Mouth Dreams - (Full Album)",
    artist:"Neil Cicierega",
  },
  {
    url:"https://www.youtube.com/watch?v=m0nIVGoFh8I",
    title:"Mouth Moods - (Full Album)",
    artist:"Neil Cicierega",
  },
  {
    url:"https://www.youtube.com/watch?v=OLj34PJauik",
    title:"haha my music taste is pretty diverse haha i hate mainstream music 😌",
    artist:"John Fassold"
  },
  {
    url:intro03,
    title:"Welcome to Poolside AM",
    artist:"@itsgregb",
  },
  {
    url:"https://www.youtube.com/watch?v=bl5TUw7sUBs",
    title:"Data & Picard",
    artist:"Pogo",
  },
  {
    url:"https://www.youtube.com/watch?v=TilaNRf60jQ",
    title:"Afroman Test Flight",
    artist:"groboclone",
  },
  {
    url:"https://www.youtube.com/watch?v=6kYco2Zt-cM",
    title:"Rage Against Vanessa Carlton",
    artist:"groboclone",
  },
  {
    url:intro04,
    title:"Welcome to Poolside AM",
    artist:"@itsgregb",
  },
  {
    url:"https://www.youtube.com/watch?v=_74N7KJmEj4",
    title:"Working as a waitress in a cocktailbar",
    artist:"The Human Leg",
  },
  {
    url:"https://www.youtube.com/watch?v=Yw6u6YkTgQ4",
    title:"hello world",
    artist:"Louie Zong",
  },
  {
    url:"https://soundcloud.com/iamprikle/play-this-at-my-funeral",
    title:"Play This At My Funeral",
    artist:"IAMPRIKLE",
  },
  {
    url:"https://www.youtube.com/watch?v=989-7xsRLR4",
    title:"The 7th Element",
    artist:"Vitas",
  },
  {
    url:intro05,
    title:"Welcome to Poolside AM",
    artist:"@itsgregb",
  },
  {
    url:"https://www.youtube.com/watch?v=dhqyMblMjGg",
    title:"All I Want For Christmas Is Yeewww",
    artist:"Mariah Carey x Soulja Boy",
  },
  {
    url:"https://www.youtube.com/watch?v=d_8IEUM2C5g",
    title:"Dump",
    artist:"groboclone",
  },
  {
    url:"https://www.youtube.com/watch?v=J_SSw-Ja4bw",
    title:"Two and a half hours of MIDI",
    artist:"Neil Cicierega",
  },
  {
    url:intro06,
    title:"Welcome to Poolside AM",
    artist:"@itsgregb",
  },
  {
    url:"https://www.youtube.com/watch?v=k55FYtqtXXU",
    title:"Our House",
    artist:"Game Grumps",
  },
  {
    url:"https://www.youtube.com/watch?v=aRsOBFhNjVM",
    title:"Steamed Hams Inc.",
    artist:"NPCarlsson",
  },
  {
    url:"https://www.youtube.com/watch?v=ZVUyyHYkBHk",
    title:"IMDABES",
    artist:"gmcfosho",
  },
];

class MediaPlayer extends React.Component {

  state = {
    ejected:false,
    current_video:Math.floor(Math.random()*video_list.length),
    pip: false,
    playing: true,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false
  }
  componentDidMount(){
    this.props.popups();
  }
  nextVideo(){
    let vidID = this.state.current_video;
    vidID++;
    if(vidID>video_list.length-1) vidID = 0;
    this.setState({current_video:vidID,playing:true});
  }

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
  }

  handleStop = () => {
    //this.setState({ url: null, playing: false })
    this.setState({ playing: false })
  }

  handleEject = () => {
    this.setState({ playing: false, ejected:true })
  }

  handleToggleLight = () => {
    this.setState({ light: !this.state.light })
  }

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop })
  }

  handleVolumeChange = e => {
    this.setState({ volume: parseFloat(e.target.value) })
  }

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted })
  }

  handleSetPlaybackRate = e => {
    this.setState({ playbackRate: parseFloat(e.target.value) })
  }

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip })
  }

  handlePlay = () => {
    this.setState({ playing: true })
  }

  handleEnablePIP = () => {
    this.setState({ pip: true })
  }

  handleDisablePIP = () => {
    this.setState({ pip: false })
  }

  handlePause = () => {
    this.setState({ playing: false })
  }

  handleSeekMouseDown = e => {
    this.setState({ seeking: true })
  }

  handleSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) })
  }

  handleSeekMouseUp = e => {
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e.target.value))
  }

  handleProgress = state => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state)
    }
  }

  handleEnded = () => {
    this.setState({ playing: this.state.loop })
  }

  handleDuration = (duration) => {
    this.setState({ duration })
  }

  ref = player => {
    this.player = player
  }
  pad(num, size) {
    var s = "000000000" + num;
    return s.substr(s.length-size);
  }
  readableDuration(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h : 0;
    var mDisplay = m > 0 ? m : 0;
    var sDisplay = s > 0 ? s : 0;
    return this.pad(hDisplay,2) + ':' + this.pad(mDisplay,2) + ':' + this.pad(sDisplay,2); 
  }

  render(){
    return (
      <div className={"media-player-inner "+(this.state.ejected ? 'ejected' : '')}>
        <div className="container">
          <img className="burt" src={burt} alt="Burt" />
          <div className="field-row">
            <input
              className="media-player-timeline"
              type='range' min={0} max={0.999999} step='any'
              value={this.state.played}
              onMouseDown={this.handleSeekMouseDown}
              onChange={this.handleSeekChange}
              onMouseUp={this.handleSeekMouseUp}
            />
            <button className="media-player-button" onClick={this.nextVideo.bind(this)}>
              Skip
            </button>
          </div>
          <ReactPlayer
            config={{ youtube: { playerVars: { disablekb: 1, modestbranding:0 } } }}
            ref={this.ref}
            width={0}
            height={0}
            url={video_list[this.state.current_video].url}
            pip={this.state.pip}
            playing={this.state.playing}
            controls={this.state.controls}
            light={this.state.light}
            loop={this.state.loop}
            playbackRate={this.state.playbackRate}
            volume={this.state.volume}
            muted={this.state.muted}
            onPlay={this.handlePlay}
            onEnablePIP={this.handleEnablePIP}
            onDisablePIP={this.handleDisablePIP}
            onPause={this.handlePause}
            onEnded={this.nextVideo.bind(this)}
            onProgress={this.handleProgress}
            onDuration={this.handleDuration}
            style={{
              pointerEvents: "none",
            }}
          />
          <div className="field-row">
            <button className="media-player-button" onClick={this.handlePlayPause}>
              <span role="img" aria-label="Play">▶</span>
            </button>
            <button className="media-player-button" onClick={this.handleStop}><span role="img" aria-label="Stop">◾️</span></button>
            <button className="media-player-button" onClick={this.handleEject}><span role="img" aria-label="Eject">⏏</span></button>
            <div className="media-player-volume">
              <label for="range25">Volume:</label>
              <input name="range25" type='range' min={0} max={1} step='any' value={this.state.volume} onChange={this.handleVolumeChange} />
            </div>
          </div>
          <hr/>
          <div className="credits">
            <div className="video-artist"><b>Artist:</b> {video_list[this.state.current_video].artist}</div>
            <div className="video-title"><b>Track:</b> {video_list[this.state.current_video].title}</div>
            <div className="video-title"><b>Duration:</b> {this.readableDuration(this.state.duration)}</div>
            {/*<img src={dancingbaby} alt="me IRL vibin to this track" />*/}
          </div>
       </div>
      </div>
    );
  }
}

export default MediaPlayer;
