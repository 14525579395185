import React from "react";

function render() {
  return (
    <pre>
      <strong>
        This is a (terrible) parody of {" "}
        <a
          href="https://poolside.fm/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Poolside FM
        </a>
      </strong>
      <br />
      Sorry not sorry ♥︎
      <br />
      <br />
      <a
        href="https://twitter.com/lochieaxon"
        target="_blank"
        rel="noopener noreferrer"
      >
        @lochieaxon
      </a>{" "}
      - micromanager
      <br />
      <a
        href="https://twitter.com/jrdntnnr"
        target="_blank"
        rel="noopener noreferrer"
      >
        @jrdntnnr
      </a>{" "}
      - dank screensaver designer
      <br />
      <a
        href="https://twitter.com/pugson"
        target="_blank"
        rel="noopener noreferrer"
      >
        @pugson
      </a>{" "}
      - designer
      <br />
      <a
        href="https://twitter.com/jcookemn"
        target="_blank"
        rel="noopener noreferrer"
      >
        @jcookemn
      </a>{" "}
      - POP QUIZ, Passwords.txt, and Limewire.exe
      <br />
      <a
        href="https://twitter.com/whereskeyser"
        target="_blank"
        rel="noopener noreferrer"
      >
        @whereskeyser
      </a>{" "}
      - popup, secret links, and vibes
      <br />
      <a
        href="https://twitter.com/itsGregB"
        target="_blank"
        rel="noopener noreferrer"
      >
        @itsGregB
      </a>{" "}
      - voice talent (and a very lovely man)
      <br />
      <a
        href="https://twitter.com/brandon_mn"
        target="_blank"
        rel="noopener noreferrer"
      >
        @brandon_mn
      </a>{" "}
      - domain hoarder, chief avoidance officer
      <br />
      <a
        href="https://twitter.com/ByeLanie"
        target="_blank"
        rel="noopener noreferrer"
      >
        @byelanie
      </a>{" "}
      - pinball, chief poolside stan officer
    </pre>
  );
}

export default render;
