import React from 'react';

import FartSounds from '../../Logic/FartSounds.js';
import StartIcon from '../../assets/windows98-icons/png/windows-5.png';

class StartBar extends React.Component {

	fart(){
		FartSounds.play()
	}

	render(){
		return (
	        <div className="start-bar">
	       		<button className="start-button" onClick={this.fart}>
		       		<div className="start-bar-icon"><img src={StartIcon} alt="Start Icon" /></div>
		       		<strong>Fart</strong>
	       		</button>
	       		<ul>
		            {this.props.windows.map((window, index) => {
						let icon;
					    if (window.icon){
						    const images = require.context("../../assets/windows98-icons/png", true);
						    icon = images("./" + window.icon + ".png");
						}
						return window.active &&
						<li key={index}>
							<button>
								{icon && <div className="start-bar-icon"><img src={icon} alt={window.label + " Icon"} /></div>}
								{window.label}
							</button>
						</li>
		            })}
		            {this.props.alerts.map((alert, index) => {
						let icon;
					    if (alert.icon){
						    const images = require.context("../../assets/windows98-icons/png", true);
						    icon = images("./" + alert.icon + ".png");
						}
						return (
						<li key={index}>
							<button>
								{icon && <div className="start-bar-icon"><img src={icon} alt={alert.label + " Icon"} /></div>}
								{alert.label}
							</button>
						</li>)
		            })}
	       		</ul>
	       		<div className="time">
	       			04:20 AM
	       		</div>
	        </div>
		);
	}



}
export default StartBar