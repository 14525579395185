import React from "react";
import ReactPlayer from "react-player";

import pinball from "./images/3D_Pinball.png";

class Pinball extends React.Component {

render(){
	return (	
		<div>
			<ReactPlayer 
			url="https://soundcloud.com/liluzivert/you-better-move"
			width={0}
			height={0}
			playing={true}
			/>
			<img alt="pinball intro" title="Pinball" src={pinball} onclick=""></img>
        </div>
	)
}

}

export default Pinball;