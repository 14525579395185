import React from "react";
import trash from "./images/trash.jpg";

function render() {
  return (
    <a href="https://twitter.com/lochieaxon/status/1223049446851665920" target="_blank" rel="noopener noreferrer">
      <img src={trash} alt="Trash" />
    </a>
  );
}

export default render;
