let canvas,ctx;
let icons = [];
const minBounce = 20;
let waterfall = false;

let openFn;

let mouse = {x:0,y:0,down:false,double:false};
function resetMouse(x,y,down){
	if(down){
		mouse.down = down;
		if( doubletap() ) DesktopIcons.click();
	}
	mouse.x = x;
	mouse.y = y;
}
var mylatesttap;
function doubletap() {
	var double = false;
	var now = new Date().getTime();
	var timesince = now - mylatesttap;
	if((timesince < 600) && (timesince > 0)){
		double = true;
	}
	mylatesttap = new Date().getTime();
	return double;
}

class DesktopIcons {

	static init(props) {
		openFn = props.openFn;
		canvas = props.canvas;
		ctx = canvas.getContext('2d');
		
		ctx.webkitImageSmoothingEnabled = false;
		ctx.mozImageSmoothingEnabled = false;
		ctx.imageSmoothingEnabled = false;

		window.addEventListener('resize', DesktopIcons.resize);
		DesktopIcons.resize();


		canvas.addEventListener("touchstart", function (e) {
			var touch = e.touches[0] || e.changedTouches[0];
			var x = touch.pageX;
			var y = touch.pageY;
			resetMouse(x,y,false);
		});
		canvas.addEventListener("touchend", function (e) {
			var touch = e.touches[0] || e.changedTouches[0];
			var x = touch.pageX;
			var y = touch.pageY;
			resetMouse(x,y,false);
		});
		canvas.addEventListener("touchmove", function (e) {
			var touch = e.touches[0] || e.changedTouches[0];
			var x = touch.pageX;
			var y = touch.pageY;
			resetMouse(x,y,true);
		});


		canvas.addEventListener("mousedown", function (e) {
			var x = e.clientX;
			var y = e.clientY;
			resetMouse(x,y,true);
		});
		canvas.addEventListener("mouseup", function (e) {
			var x = e.clientX;
			var y = e.clientY;
			resetMouse(x,y,false);
		});
		canvas.addEventListener("mousemove", function (e) {
			var x = e.clientX;
			var y = e.clientY;
			resetMouse(x,y);
		});


		for(var i=0;i<props.windows.length;i++){
			var a = props.windows[i];
			
		    const images = require.context("../assets/windows98-icons/png", true);
		    let icon = images("./" + a.icon + ".png");
			var image = new Image();
			image.src = icon;

			var pos = DesktopIcons.getPosition(i);
			icons.push({
				action:a.action,
				image:image,
				label:a.label,
				x: pos.x,
				y: pos.y,
				width:pos.width,
				height:pos.height,
				xSpeed:5 + Math.random()*3,
				xv:3,
				yv:-(5 + Math.random()*1),
				rect:false,
			});
		}
		DesktopIcons.loop();
	}

	static getPosition(i){
		var width = 100;
		var height = 100;
		var padding = 20;

		var w = width + padding;
		var h = height + padding;

		var maxY = Math.floor(canvas.height/h);
		var xStep = Math.floor(i/maxY);

		var x = (xStep * w);
		var y = (i%maxY * h);

		return{
			x:x,
			y:y,
			width:width,
			height:height
		}
	}

	static resize(){
		canvas.width = window.innerWidth;
		canvas.height = window.innerHeight;
		if(!waterfall){
			for(var i=0;i<icons.length;i++){
				var a = icons[i];
				var pos = DesktopIcons.getPosition(i);
				a.x = pos.x;
				a.y = pos.y;
				a.width = pos.width;
				a.height = pos.height;
			}
		}
	}

	static setWaterfall(state){ waterfall = state; }
	static loop(){
		DesktopIcons.update();
		DesktopIcons.waterfallUpdate();
		DesktopIcons.render();
   	 	requestAnimationFrame(DesktopIcons.loop);
	}

	static hitTest(a){
		return (mouse.x > a.x && mouse.x < a.x+a.width)
			&& (mouse.y > a.y && mouse.y < a.y+a.height);
	}

	static click(){
		for(var i=0;i<icons.length;i++){
			var a = icons[i];
			if( DesktopIcons.hitTest(a) ){
				a.action ? a.action() : openFn(i);
			}
		}
	}

	static update(){
		for(var i=0;i<icons.length;i++){
			var a = icons[i];
			if( DesktopIcons.hitTest(a) ){
				a.hover = true;
			}else{
				a.hover = false;
			}
		}
	}

	static waterfallUpdate(){
		if(! waterfall) return;
		for(var i=0;i<icons.length;i++){
			var a = icons[i];

			let xSpeed = a.xSpeed
			let xv = a.xv;
			let yv = a.yv + 0.25;

			xv += (a.xSpeed - xv) * 0.05;

			if(a.y>(canvas.height-a.height)){
				yv = - (yv/2); // bounce
				yv = yv < -minBounce ? yv : -minBounce
			}
			if(a.x>canvas.width - a.width){
				xv = -xv;
				xSpeed = -xSpeed;
			}
			if(a.x<0){
				xv = -xv;
				xSpeed = -xSpeed;
			}

			// Assign
			a.x = a.x + xv;
			a.y = a.y + yv;
			a.xv = xv;
			a.yv = yv;
			a.xSpeed = xSpeed;
		}
	}

	static render(){
		if(! waterfall) ctx.clearRect(0,0,canvas.width,canvas.height);

		for(var i=0;i<icons.length;i++){
			var a = icons[i];

			var hpadding = 15;
			var wpadding = 25;

			var x = Math.floor(a.x+wpadding);
			var y = Math.floor(a.y+hpadding);
			var w = Math.floor(a.width-(wpadding*2));
			var h = Math.floor(a.width-(wpadding*2));
			ctx.drawImage(a.image, x, y, w, h);

			var txt = {
				x:a.x + (a.width/2), 
				y:a.y+(a.height-hpadding),
				size:12,
			}
			ctx.font = txt.size+"px Arial";
			ctx.fillStyle = "white";
			ctx.textAlign = "center";
			ctx.fillText(a.label, txt.x, txt.y);

			if(a.hover){
				var size = ctx.measureText(a.label);
				ctx.setLineDash([2]);
				ctx.strokeStyle = "#FFFFFF";
				var p = 4;
				ctx.strokeRect((txt.x-(size.width/2))-p, (txt.y-txt.size), (size.width)+(p*2), txt.size+(p));
			}
		}
	}

}
export default DesktopIcons;