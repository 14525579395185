import React from 'react';

import UIfx from 'uifx';
import errorSound from "./../assets/sounds/CHORD.WAV";
function BlueScreen(){

    const sfx = new UIfx(
      errorSound,
      {
        volume: 1,
        throttleMs: 100
      }
    );
    sfx.play()

	return (
		<div className="bluescreen">
			<div className="content glitch">
				<strong>Poolside AM</strong>
				<p>A problem has been detected and Poolside AM has been shut down to prevent further damage to your braincells.</p>
				<p>If this screen appears again, follow these steps:</p>
				<p>> Check to make sure that you are aware that this is a parody and go tell your local music enthusiast to go visit <a href="https://poolside.fm/" target="_blank" rel="noopener noreferrer">Poolside FM</a>.</p>
				<p>> If problems continue, disable or remove yourself from this website, it's useless.</p>
				<p>Technical information:<br/>
				> START: 0x420420 (0x00000069)</p>
			</div>
		</div>
	);
}
export default BlueScreen;