import React from "react";
import wojtek from "./images/wojtek.jpg";

function render(){
	return (
		<div>
			<p>This is My Computer</p>
            <img src={wojtek} alt="Pugson's Computer" />
        </div>
	)
}

export default render;