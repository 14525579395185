import React from "react";
import warningSign from "./images/msg_warning-0.png";

function render({ onClick }) {
  return (
    <div className="shittyPopUp">
      <p>
        <img src={warningSign} alt="Warning" /> Does your computer have a virus?
        Cause these beats are SICK
      </p>
      <br></br>
      <button onClick={onClick}>Proceed, bruh</button>
    </div>
  );
}

export default render;
