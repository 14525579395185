import React from "react";

function render(){
	return (
		<div>
			<p>I'm a self-loathing member of:</p>
			<div className="field-row">
				<input id="radio1" type="radio" name="tech" />
				<label for="radio1">Tech Twitter™</label>
			</div>
			<div className="field-row">
				<input id="radio2" type="radio" name="design" />
				<label for="radio2">Design Twitter™</label>
			</div>
			<div className="field-row">
				<input id="radio3" type="radio" name="vc" />
				<label for="radio3">VC Twitter™</label>
			</div>
		</div>
	)
}

export default render;