import React from "react";
import DesktopIcons from '../../Logic/DesktopIcons.js';

class Desktop extends React.Component {

  constructor(){
    super();
    this.canvasRef = React.createRef();
  }
  componentDidMount(){
    DesktopIcons.init({
      canvas:this.canvasRef.current,
      windows:this.props.windows,
      openFn:this.props.open,
    });
  }
  render() {
    DesktopIcons.setWaterfall( this.props.waterfall );
    return (
      <div>
        <canvas ref={this.canvasRef} style={{position:'fixed',top:0,left:0}} />
      </div>
    );
  }
}
export default Desktop;
