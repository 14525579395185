import React from 'react';
import "98.css";
import './assets/scss/style.scss';

import Explorer from './Components/Explorer.js';

function App() {
	return (
		<div className="App">
			<Explorer />
		</div>
	);
}

export default App;
