import React from "react";

import StartBar from "./StartBar/StartBar.js";
import Desktop from "./Desktop/Desktop.js";
import Window from "./Window/Window.js";

import Screensaver from './Screensavers/index.js';
import Intro from './Intro.js';
import BlueScreen from "./BlueScreen.js";

import Credits from "./_Windows/Credits.js";
import VideoPlayer from "./_Windows/VideoPlayer.js";
import PopQuiz from "./_Windows/PopQuiz.js";
import MyComputer from "./_Windows/MyComputer.js";
import MediaPlayer from "./_Windows/MediaPlayer.js";
import RecyclingBin from "./_Windows/RecyclingBin.js";
import Passwords from "./_Windows/Passwords.js";
import Pinball from "./_Windows/Pinball.js";
import Limewire from "./_Windows/Limewire.js";
import InternetExplorer from "./_Windows/InternetExplorer.js";
import PopUp from "./_Windows/PopUp";
import Paint from "./_Windows/Paint";
import PoolsideAM from "./_Windows/PoolsideAM";

import UIfx from 'uifx';
import sega from "./../assets/sounds/sega.mp3";
import clickSound from "./../assets/sounds/START.WAV";
import DING from "./../assets/sounds/DING.WAV";

class Explorer extends React.Component {
  state = {
    intro:true,
    popups:true,

    waterfall:false,
    bluescreen:false,

    windows:[
      {
        active:false,
        label:"My Computer",
        icon:"computer_explorer-4",
        content:<MyComputer/>
      },
      {
        active:false,
        label:"Media Player",
        icon:"multimedia-2",
        content:<MediaPlayer popups={this.openPopups.bind(this)}/>
      },
      {
        active:false,
        label:"Internet Explorer",
        icon:"msie1-2",
        content:<InternetExplorer bluescreen={this.everythingsFucked.bind(this)}/>
      },
      {
        active:false,
        label:"Recycling Bin",
        icon:"recycle_bin_full-0",
        content:<RecyclingBin/>
      },
      {
        active:false,
        label:"POPQUIZ.exe",
        icon:"certificate_2_excl-0",
        content:<PopQuiz/>
      },
      {
        active:false,
        label:"credits.txt",
        icon:"notepad-1",
        content:<Credits/>
      },
      {
        active:false,
        label:"passwords.txt",
        icon:"address_book_pad",
        content:<Passwords/>
      },
      {
        active:false,
        label:"Limewire.exe",
        icon:"LimeWire-logo",
        content:<Limewire/>
      },
      {
        active:false,
        label:"Poolside AM.exe",
        icon:"executable-0",
        content:<PoolsideAM/>
      },
      {
        label:"not-a-virus.exe",
        icon:"msg_warning-0",
        action:this.apocalypse.bind(this)
      },
      {
        label:"Poolside Solitaire",
        icon:"spider-0",
        action:this.justFuckMeUpFam.bind(this)
      },
      {
        active:false,
        label:"Poolside Paint",
        icon:"paint_file-4",
        content:<Paint/>
      },
      {
        active:false,
        label:"Pinball",
        icon:"pinball",
        content:
          <Pinball />
      },
      {
        active:false,
        label:"Poolside TV",
        icon:"video_-1",
        content:
          <VideoPlayer />
      },
    ],
    alerts:[]
  };
/*
  constructor(){
    super();
  }
*/
  componentDidMount(){
    setTimeout(this.startUp.bind(this),12000);
  }

  clickSound(){
    const sfx = new UIfx(
      clickSound,
      {
        volume: 1,
        throttleMs: 100
      }
    );
    sfx.play()

    if(Math.random()>0.99){
      for(var i=0;i<10;i++){
        setTimeout(this.createPopup.bind(this),100*i)
      }
    }
  }

  startUp(){
    const sfx = new UIfx(
      sega,
      {
        volume: 1,
        throttleMs: 100
      }
    );
    sfx.play()
    this.setState({intro:false});
    setTimeout(this.openApps.bind(this),6000);
  }
  openApps(){
    this.open(1);
    this.open(this.state.windows.length-1);
  }

  openPopups(){
    setTimeout(this.createPopups.bind(this),10000);
    setInterval(this.createPopups.bind(this),60000);
  }
  apocalypse(){
    for(var i=0;i<1000;i++){
      setTimeout(this.createPopup.bind(this),100*i)
    }
  }
  createPopups(){
    if(!this.state.popups && !this.state.bluescreen) return;
    let amount = 1 + Math.floor(Math.random()*7);
    if(Math.random()>0.99) amount = 1000;
    for(var i=0;i<amount;i++){
      setTimeout(this.createPopup.bind(this),100*i)
    }
  }
  createPopup(){
    let alerts = this.state.alerts;
    alerts.push({
      active:false,
      label:"VIRUS ALERT",
      icon:"no-1",
      position:"random",
      content:PopUp
    });
    this.setState({
      alerts:alerts
    });

    const sfx = new UIfx(
      DING,
      {
        volume: 1,
        throttleMs: 100
      }
    );
    sfx.play()
  }
  closePopup(index) {
    let alerts = this.state.alerts;
    alerts.splice(index,1);
    this.setState({ alerts:alerts })    
  }

  open(index) {
    let windows = this.state.windows;
    windows[index].active = true;
    this.setState({ windows:windows });
  }

  justFuckMeUpFam(){
    this.setState({ waterfall:true });
  }

  everythingsFucked(){
    this.setState({ bluescreen:true });
  }
  close(index) {
    let state = this.state;
    state.windows[index].active = false;
    this.setState(state);
  }

  render() {
    if(this.state.intro) return <Intro />;
    if(this.state.bluescreen) return <BlueScreen />
    return (
      <div onClick={this.clickSound.bind(this)} className="home">
        <Screensaver />

        <Desktop windows={this.state.windows} open={this.open.bind(this)} waterfall={this.state.waterfall} />

        <StartBar windows={this.state.windows} alerts={this.state.alerts} />

        <div className="windows">
          {this.state.windows.map((window, index) => {
            return (window.active &&
              <Window
                key={index}
                n={index+1}
                title={window.label}
                icon={window.icon}
                onClose={() => this.close(index)}
              >{window.content}</Window>
            );
          })}
          {this.state.alerts.map((alert, index) => {
            return (<Window
                key={index}
                title={alert.label}
                icon={alert.icon}
                needsAttention={true}
                onClose={this.closePopup.bind(this,index)}
              ><alert.content onClick={this.closePopup.bind(this,index)}/></Window>
            );
          })}
        </div>

      </div>
    );
  }
}
export default Explorer;
