import React from 'react';
import Maze from './maze.js';
class Screensaver extends React.Component {

	state = {
		timeToSaveScreen:60 * 5, // in seconds
		idleTime:0,
		idleInverval:0,
	}

	componentDidMount(){
		const timeToSaveScreen = this.state.timeToSaveScreen * 1000;
		this.setState({
			idleTime:0,
			idleInterval:setInterval(this.timerIncrement.bind(this), timeToSaveScreen)
		})
		document.addEventListener('mousemove', (e) => {
			this.timerReset();
		});
		document.addEventListener('mousedown', (e) => {
			this.timerReset();
		});
		document.addEventListener('keypress', (e) => {
			this.timerReset();
		});
	}

	timerReset(){
		this.setState({ idleTime: 0, isIdle:false });
	}

	timerIncrement(){
		this.setState({ idleTime: this.state.idleTime+1 });
	}

	render(){
		if(this.state.idleTime < 1) return false;
		return (
	        <div className="screensaver">
		        <Maze/>
	        </div>
		);
	}
}
export default Screensaver;